<template>
  <div class="disclosure">
    <h1>2023 IONIQ 6 Charging Plan</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color">
      <strong>Effective as of January 2023</strong>
    </p>
    <p>
      Electrify America and Hyundai are pleased to provide this promotional charging program to drivers of new model
      year 2023 IONIQ 6 vehicles purchased on or before February 28, 2025 from an authorized U.S.&ndash;based Hyundai
      dealer (excluding dealers located in Alaska). By clicking “Agree” below, you agree to participate in this
      promotion, subject to your compliance with these terms and conditions and all other terms and policies referenced
      herein.
    </p>
    <p>
      Participation requires an Electrify America account, the Electrify America mobile app, enrollment in the 2023
      IONIQ 6 Charging Plan, and acceptance of the Electrify America
      <router-link class="link" :to="{ name: 'terms-en' }">Terms of Use</router-link> and
      <router-link class="link" :to="{ name: 'privacy-en' }">Privacy Policy</router-link> . Your mobile carrier’s
      messaging, data, and other rates and fees may apply to your use of the Electrify America app. You may also be
      required to use a credit or debit card to load dollars to an account for idle fees or other charging sessions not
      covered by your plan and agree to an automatic reload feature. See the Electrify America Terms of Use available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> for
      further details.
    </p>
    <p>
      Immediately upon your registration and acceptance of these terms, you will be enrolled in a 24-month program
      (starting from the date of vehicle purchase) that provides 30 minutes of complimentary charging during DC fast
      (CCS) charging sessions and 60 minutes of complimentary charging during Level 2 charging sessions for your IONIQ
      6. You may redeem this credit on Electrify America’s network of electric vehicle charging stations.
    </p>
    <p>
      After signing up, you can redeem your complimentary charging during your initiation of a charging session at any
      Electrify America charging station, exclusively by using the Electrify America app “Swipe to start” or NFC Pass
      feature with the appropriate plan selected. This complimentary charging may not be initiated using the interface
      screen or buttons on the charger.
    </p>
    <p>
      With the exclusion of pre-funded idle fees per our
      <router-link class="link" :to="{ name: 'terms-en' }">Terms of Use</router-link>, there is no additional fee,
      purchase, or subscription required for your access to this promotion. Subject to the restrictions below, there is
      no limit on the number of eligible charging sessions during the 24- month period of this promotion.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <p>
      You may <strong>only</strong> use this complimentary credit for charging the IONIQ 6 whose VIN you submitted when
      signing up for this promotion on your Electrify America account.
      <strong>No other vehicle is eligible to receive this credit through your account,</strong> whether owned by you or
      others. You may not use this promotion benefit for commercial purposes, including but not limited to fleet use or
      use in a taxi or ride share program.
    </p>
    <p>
      Charging after the initial 30 minutes on a DC Fast charger or the initial 60 minutes at a Level 2 charger
      continues at prevailing Pass pricing (idle fees and applicable taxes not included). Complimentary charging time
      <strong>does not include</strong> any idle fees or applicable taxes assessed at the charging station. You will be
      responsible for paying such fees through your account if you incur them during a charging session. There is a
      <strong>60-minute waiting period between eligible charging sessions,</strong> and the promotion benefits will not
      apply to a charging session initiated within this period.
    </p>
    <p>
      You must follow all product, vehicle, safety, and technical documentation included with the IONIQ 6 when charging
      at an Electrify America charging station.
    </p>
    <p>You must use the correct connector type for the IONIQ 6 at the charging station.</p>
    <p>
      Once the term of this promotion has terminated or expired, you will not be able to reload, renew, or add more
      credit to this promotional plan. Please refer to the information on the
      <strong class="primary-color">“Plan Details”</strong> section of the app to keep track of the amount of time
      remaining in this promotion.
    </p>
    <p>
      This complimentary charging is not returnable or redeemable for any cash value, and may not be sold, loaned, or
      otherwise distributed to any third party for any reason. You may not transfer any portion of the credit to any
      other Electrify America account. This complimentary charging is for your personal use only, and the plan is not
      authorized, intended, or available for any commercial use (such as ride-hailing and ridesharing).
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> and that
      your use of the Electrify America mobile app is subject to the Terms of Use accessible by navigating to the
      <strong class="primary-color">Profile</strong> icon in top right hand corner of map, selecting
      <strong class="primary-color">“Legal”</strong> and then selecting
      <router-link class="medium" :to="{ name: 'terms-en' }">“Terms of Use”</router-link> &amp;
      <router-link class="medium" :to="{ name: 'privacy-en' }">“Privacy Policy.”</router-link> Please read these Terms
      of Use documents carefully for information about how you can pay through your account for idle fees and applicable
      taxes as well as for charging session time outside of the complimentary charging offered under this promotion.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, or any
      other contract between you and Electrify America; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      America services, the Electrify America mobile app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this complimentary promotion at any time before its automatic
      termination date for any reason, in its sole discretion, provided that Electrify America will send an email to the
      address associated with your account in the event of any change to the scheduled end date of this promotion.
      Following the termination or expiration of this program for any reason, your access to the complimentary promotion
      will immediately cease, and you may not use or redeem any additional complimentary charging time. At that time,
      your account will automatically convert to a basic Electrify America “Pass” account, at no additional fee or
      charge to you (neither one-time nor recurring). You may, but are not obligated to, sign up for a different type of
      subscription via the Electrify America mobile app.
    </p>
    <br />
  </div>
</template>

<script>
export default {
  name: '2023-ioniq6-charging-plan-disclosure',
  metaInfo: {
    title: '2023 Hyundai Ioniq 6 - Disclosure | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/2023-ioniq6-charging-plan-disclosure/' }],
  },
};
</script>
